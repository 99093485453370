<template>
  <div class="gx-5 center-container">
    <div>
      <div class="layout-header sticky-top bg-white">
        <Divider v-if="isTaskClosed" :label="$t('tasks.singularTaskLayout.closedTask')" />
        <div class="row">
          <div class="d-flex justify-content-between top-navbar-style">
            <div class="d-flex align-items-center">
              <div class="play-mode-indicator">
                <slot name="play-mode-indicator"></slot>
              </div>
              <h1>{{ title }}</h1>
              <TaskTag class="mx-2" :task="task" :display-mode="true" />
              <el-tooltip
                v-if="task?.reviewRequired"
                :content="$t('tasks.singularTaskLayout.managerReviewRequired')"
                placement="top"
              >
                <FlagFullIcon v-if="task?.reviewRequired" color="#E47E03" />
              </el-tooltip>
            </div>

            <SingularTaskNavBarActions
              :task="task"
              :is-loading-task="isLoadingTask"
              :validate-task-before-complete-hook="validateTaskBeforeCompleteHook"
              :requests-target-businesses-ids="requestsTargetBusinessesIds"
              :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
              :is-actions-disabled="isActionsDisabled"
              :is-task-closed="isTaskClosed"
              :is-skip-action-disabled="isSkipActionDisabled"
              :request-templates="requestTemplates"
              @complete-task="handleCompleteTask"
              @skip-task="openSnoozeModal = true"
              @is-manager-review-required="handleReviewManagerClicked"
              @template-selected="$emit('template-selected', $event)"
            >
              <template #pagination>
                <slot name="pagination"></slot>
              </template>

              <template #menu-dropdown-items>
                <slot name="menu-dropdown-items"></slot>
              </template>
            </SingularTaskNavBarActions>
          </div>
        </div>
        <div>
          <div
            class="comment-box"
            @mouseenter="showReviewEditButton = true"
            @mouseleave="showReviewEditButton = false"
            @click="commentModalOpen = true"
          >
            <div class="m-4 comment-text">
              <div class="comment-title">
                {{ $t('tasks.singularTaskLayout.comments') }}
              </div>
              <span v-if="task?.data?.comment">{{ task?.data?.comment }}</span>
              <span v-else class="comment-placeholder">{{ $t('tasks.singularTaskLayout.commentPlaceholder') }}</span>
              <span v-if="showReviewEditButton">
                <EditSolidIcon width="11" height="11" class="pencil-icon mx-2"></EditSolidIcon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <slot name="content"></slot>
      </div>
      <div class="pb-4">
        <TaskSnoozeModal
          v-if="openSnoozeModal"
          :task="task"
          @snooze-task="handleSkipTask"
          @close="openSnoozeModal = false"
        >
        </TaskSnoozeModal>
        <TaskCommentModal
          v-if="commentModalOpen"
          :comment="task?.data?.comment"
          @commentSubmitted="handleTaskReviewChange"
          @close="commentModalOpen = !commentModalOpen"
          @cancel="commentModalOpen = !commentModalOpen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { TaskTag } from '@/modules/tasks/taskTag';
import { EditSolidIcon, FlagFullIcon } from '@/assets/icons';
import TaskSnoozeModal from './TaskSnoozeModal.vue';
import TaskCommentModal from './TaskCommentModal.vue';
import SingularTaskNavBarActions from './SingularTaskNavBarActions.vue';
import { useNotification } from '@/modules/core';

import { useCompleteTaskNew2, usePatchTaskNew2, useActivateTaskNew2 } from '@/modules/tasks';
import { useActivityTrack } from '@/modules/tasks/compositions';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { isNil } from 'ramda';
import Divider from './Divider.vue';

export default {
  components: {
    TaskTag,
    TaskSnoozeModal,
    TaskCommentModal,
    SingularTaskNavBarActions,
    EditSolidIcon,
    FlagFullIcon,
    Divider,
  },
  props: {
    title: { type: String, required: true },
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
    validateTaskBeforeCompleteHook: {
      type: Function,
      required: true,
      default: async () => {
        return {
          errors: [],
          warnings: [],
        };
      },
    },
    requestsTargetBusinessesIds: {
      type: Array,
      required: false,
      default() {
        return null;
      },
    },
    requestTemplates: { type: Array, required: false, default: () => [] },

    shouldDisableIgnoreWarnings: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isActionsDisabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isSkipActionDisabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ['complete-task', 'skip-task'],
  setup(props, { emit }) {
    const { completeTask } = useCompleteTaskNew2();
    const { error } = useNotification();

    const openSnoozeModal = ref(false);
    const commentModalOpen = ref(false);
    const showReviewEditButton = ref(false);

    const { patchTask } = usePatchTaskNew2();
    const { activeTask } = useActivateTaskNew2();
    useActivityTrack(
      computed(() => props?.task?.id),
      computed(() => `${props?.task?.id}-${Date.now()}`),
      2000
    );

    const isTaskClosed = computed(() => {
      return !isNil(props?.task?.completedAt);
    });

    const taskType = computed(() =>
      props?.task?.type && props?.task?.domain ? `${props.task.type}_${props.task.domain}` : ''
    );

    const patchTaskManagerReview = async (isReviewRequired) => {
      await patchTask({
        taskId: props?.task.id,
        patchParams: {
          reviewRequired: isReviewRequired,
        },
      }).catch((err) => {
        error({ title: err.error, message: err.reason });
      });
    };

    return {
      handleTaskReviewChange: async (comment) => {
        const data = { ...(props?.task.data || {}) };
        if (comment) {
          data.comment = comment;
        } else {
          delete data.comment;
        }

        const patchParams = { data };

        await patchTask({
          taskId: props?.task.id,
          patchParams,
        }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });

        commentModalOpen.value = false;
      },
      handleReviewManagerClicked: async (isManagerReviewRequired) => {
        if (!isManagerReviewRequired) {
          await patchTaskManagerReview(isManagerReviewRequired);
        } else {
          openSnoozeModal.value = true;
        }
      },

      handleCompleteTask: async () => {
        await completeTask({ taskId: props.task.id }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });

        const taskData = {
          reviewRequired: props.task.reviewRequired,
          activeAt: props.task.activeAt,
          data: props.task.data,
          taskType: taskType.value,
          analyticsEventName: ANALYTICS_EVENT_TYPES.TASKS.COMPLETED,
        };
        emit('complete-task', props.task.id, taskData);
      },

      handleSkipTask: async ({ activeAt, comment, reviewRequired }) => {
        const taskId = props.task.id;
        const originalData = { ...(props.task.data || {}) };

        const data = { ...originalData, comment };
        const patchParams = { data, reviewRequired };

        await patchTask({
          taskId: taskId,
          patchParams,
        }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });

        if (activeAt) {
          await activeTask({
            taskId: taskId,
            activeParams: {
              activeAt,
            },
          }).catch((err) => {
            error({ title: err.error, message: err.reason });
          });
        }

        const taskData = {
          reviewRequired: !props.task.reviewRequired && reviewRequired,
          activeAt,
          data,
          taskType: taskType.value,
          analyticsEventName:
            ANALYTICS_EVENT_TYPES.TASKS[
              !props.task.reviewRequired && reviewRequired ? 'MANAGER_REVIEW_REQUIRED' : 'SNOOZED'
            ],
        };
        openSnoozeModal.value = false;
        emit('skip-task', taskId, taskData);
      },
      openSnoozeModal,
      commentModalOpen,
      showReviewEditButton,
      isTaskClosed,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.comment-box {
  display: flex;
  flex-direction: row;
  background-color: #fbfbfb;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    background-color: #f3f3f4;
  }
  & .comment-title {
    font-weight: 500;
    margin-bottom: 4px;
  }

  & .comment-text {
    color: $typography-primary;
    max-width: 560px;
    white-space: pre-wrap;
    display: inline-block;
    word-wrap: break-word;
  }

  & .comment-placeholder {
    color: $typography-secondary;
  }

  cursor: pointer;
}

.comment-box > div {
  margin-right: 10px;
}

.pencil-icon {
  color: $typography-secondary;
}

.center-container {
  width: 100%;
  margin: 0 auto;
}

.top-navbar-style {
  height: 142px;
  align-items: center;
}

.layout-header {
  padding-bottom: 48px;
}

.play-mode-indicator {
  position: absolute;
  top: 30px;
}
</style>
